import { Directive, Component } from "@angular/core";
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from "@angular/forms";
import { FieldType } from "@ngx-formly/core";

@Directive({
    selector: "input[type=file]",
    // tslint:disable-next-line: no-host-metadata-property
    host: {
        "(change)": "onChange($event.target.files)",
        "(blur)": "onTouched()",
    },
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: FileValueAccessorDirective,
            multi: true,
        },
    ],
})
export class FileValueAccessorDirective implements ControlValueAccessor {
    value: any;
    onChange = (_) => {};
    onTouched = () => {};

    writeValue(value) {}
    registerOnChange(fn: any) {
        this.onChange = fn;
    }
    registerOnTouched(fn: any) {
        this.onTouched = fn;
    }
}

@Component({
    selector: "formly-field-file",
    template: `
        <input
            type="file"
            [formControl]="formControl"
            [formlyAttributes]="field"
        />
    `,
})
export class FormlyFieldFileComponent extends FieldType {}
