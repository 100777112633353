// "core-ui": "file:../cucumber.mvp.core.ui/dist/core-ui/core-ui-0.0.2.tgz",

/**
 * Angular Core
 */
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { DataTableComponent } from "src/app/components/data-table/data-table.component";
import { FormMasterComponent } from "src/app/components/form-master/form-master.component";
import { SiteNavComponent } from "src/app/components/site-navigation/site-navigation.component";
import { UiBlockerComponent } from "src/app/components/ui-blocker/ui-blocker.component";
import { ViewMasterComponent } from "src/app/components/view-master/view-master.component";
import { AppFormlyModule } from "src/app/modules/formly.module";
import { MaterialModule } from "src/app/modules/material.module";

@NgModule({
    declarations: [
        FormMasterComponent,
        DataTableComponent,
        SiteNavComponent,
        ViewMasterComponent,
        UiBlockerComponent,
    ],
    exports: [
        CommonModule,
        RouterModule,
        MaterialModule,
        AppFormlyModule,
        FormMasterComponent,
        DataTableComponent,
        SiteNavComponent,
        ViewMasterComponent,
        UiBlockerComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        MaterialModule,
        AppFormlyModule,
        AppFormlyModule,
    ],
    providers: [DataTableComponent],
})
export class SharedModule {}
