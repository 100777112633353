import {
    Component,
    OnDestroy,
    OnChanges,
    OnInit,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from "@angular/core";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { PaginationConfig } from "../../types/pagination-config";
import { TableAction } from "../../types/table-config";

@Component({
    selector: "app-data-table",
    templateUrl: "./data-table.component.html",
    styleUrls: ["./data-table.component.scss"],
})

/**
 * @description Implements mat-table features eg; pagination, sort and page size
 * @todo implement quickSearch
 * @todo implemnt search by column
 * @todo implement dynamic column feature
 */
export class DataTableComponent implements OnInit, OnDestroy, OnChanges {
    @Input() inMemory = false;
    @Input() canFilter = false;
    @Input() canQuickSearch = this.inMemory;
    @Input() canConfigure = false;
    @Input() useFactoryColumns = false;
    @Input() actions: TableAction[] = [];

    @Input() data: any[] = [];

    @Input() factory = {
        sortActive: "",
        sortDirection: "asc",
        columns: [],
    };

    @Input() paginationConfig: PaginationConfig = {
        length: 100,
        pageSize: 10,
        currentPage: 0,
        pageSizeOptions: [5, 10, 25, 100],
        totalRecords: 0,
    };

    @Output() pageEvent = new EventEmitter<any>();
    @Output() sortEvent = new EventEmitter<any>();
    @Output() initialized = false;
    @Output() selectRow: EventEmitter<any> = new EventEmitter();
    @Output() selectAction: EventEmitter<any> = new EventEmitter();

    @ViewChild(MatSort, { static: false }) sort: MatSort;

    noResults = false;
    loading = false;
    currentData: any[] = this.data;
    dataSource = new MatTableDataSource<any>();
    paginator: MatPaginator;
    displayedColumns: string[] = [];

    constructor() {}
    ngOnInit() {}

    /**
     *  @description listen to changes in the data and re-render the component when the data changes
     */
    ngOnChanges(): void {
        if (this.data.length > 0) {
            this.noResults = false;
            this.buildTable();
            this.initialized = true;
        } else {
            this.noResults = true;
            this.initialized = false;
        }
    }

    ngOnDestroy(): void {}

    /**
     * @description supplies the new data to the mat-table component
     */
    buildTable(): void {
        if (this.useFactoryColumns) {
            this.displayedColumns = this.factory.columns.map((column) => {
                return column.columnDef;
            });
        } else {
            this.displayedColumns = Object.keys(this.data[0]);
        }

        if (this.actions.length < 1) {
            this.displayedColumns = this.displayedColumns.filter(
                (e) => e !== "actions",
            );
        }

        if (this.inMemory) {
            this.currentData = this.data;
            this.dataSource = new MatTableDataSource<any>(
                this.currentData.slice(
                    this.paginationConfig.currentPage *
                        this.paginationConfig.pageSize,
                    this.paginationConfig.pageSize,
                ),
            );
        } else {
            this.currentData = this.data;
            this.dataSource = new MatTableDataSource<any>(this.currentData);
        }

        this.dataSource.sort = this.sort;
    }

    /**
     * @description changes the route to navigate to the individual detail page
     * @param index row data uuid number
     */
    public getDetails(index: number): void {
        // this.router.navigate([this.data[index].url]);
    }

    public isInitialized(): boolean {
        return this.initialized;
    }

    /**
     * @description handles the page event on pagination change
     * @param page pagination event
     */
    public handlePage = (page: PageEvent) => {
        if (this.inMemory) {
            this.paginate(page);
        }

        this.pageEvent.emit({
            page: page.pageIndex,
            size: page.pageSize,
        });
    };

    /**
     * @description if all the data is already in memory paginate internally
     * @param page pagination event
     */
    private paginate(page: PageEvent) {
        const startIndex = page.pageIndex * page.pageSize;
        const endIndex = startIndex + page.pageSize;

        this.currentData = this.data.slice(startIndex, endIndex);

        this.dataSource = new MatTableDataSource<any>(this.currentData);
    }

    /**
     * @description when a column header is clicked either sort the data internally or ask the api
     * @param sort sort event data
     */
    public handleSortBy(sort: Sort): void {
        (this.factory.sortActive = sort.active),
            (this.factory.sortDirection = sort.direction);

        if (this.inMemory) {
            this.sortBy(sort);
        }

        this.sortEvent.emit({
            sort: `${sort.active},${sort.direction}`,
            page: 0,
        });
    }

    /**
     * @description if all the data is already in memory sort internally
     * @param sort sort event data
     */
    private sortBy(sort: Sort) {
        const currentData = this.data.slice();

        if (!sort.active || sort.direction === "") {
            this.currentData = currentData;
            return;
        }

        this.data = this.data.sort((a, b) => {
            const isAsc = sort.direction === "asc";
            return this.compare(a[sort.active], b[sort.active], isAsc);
        });

        this.currentData = currentData.sort((a, b) => {
            const isAsc = sort.direction === "asc";
            return this.compare(a[sort.active], b[sort.active], isAsc);
        });

        this.dataSource = new MatTableDataSource<any>(
            this.currentData.slice(0, this.paginationConfig.pageSize),
        );
    }

    /**
     * @description sort helper.
     */
    private compare(a: number | string, b: number | string, isAsc: boolean) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    /**
     * @description if no data is returned from the dataService, return true.
     */
    public showNoResults(): boolean {
        return this.data === undefined;
    }

    /**
     * @description shortens the column header text to format nicely in the table.
     */
    public truncateText(input: string): string {
        return input && input.length > 20
            ? `${input.substring(0, 20)}...`
            : input;
    }

    onClickRow(row, $event): void {
        $event.stopPropagation();
        this.selectRow.emit(row);
    }

    onClickAction(item, action, $event): void {
        $event.stopPropagation();
        this.selectAction.emit({ action, item });
    }

    disableAction(item) {
        return item?.disableAction;
    }
}
