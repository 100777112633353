import { Inject } from "@angular/core";
import { KeycloakService } from "keycloak-angular";

/**
 * @description - initialiser to run on keycloak init to set the configuration and options. If environment is develop,
 *  stores the keycloak tokens in local storage.
 *
 * @param keycloak - keycloak service
 */
export function initializer(keycloak: KeycloakService) {
    const config = window.env.keycloakConfigOptions;
    const initOptions = window.env.keycloakInitOptions;

    return (): Promise<any> => {
        return new Promise(async (resolve, reject) => {
            try {
                await keycloak.init({
                    config,
                    initOptions,
                    bearerExcludedUrls: [],
                });

                resolve(true);
            } catch (error) {
                reject(error);
            }
        });
    };
}
