/**
 * @description the expected keycloak instance configurations
 */
export interface KeycloakRealmConfig {
    url: string;
    realm: string;
    clientId: string;
}

/**
 * @description keycloak options
 */
export interface KeycloakInitOptionsConfig {
    onLoad: string;
    checkLoginIframe: boolean;
}

/**
 * @description combined instance configurations and options
 */
export interface KeycloakSettings {
    configOptions: KeycloakRealmConfig;
    initOptions: KeycloakInitOptionsConfig;
}

/**
 * @description expected window.env settings that should be provided to the application
 */
export declare class AppSettings {
    host: string;
    port: number;
    version: string;
    keycloakApiHost: string;
    keycloakApiPath: string;
    keycloakConfigOptions: KeycloakSettings;
    keycloakInitOptions: KeycloakInitOptionsConfig;
    showTestBanner: string;
}

/**
 * @description AppSettingsService, a Service that collects window.env settings
 * and passes application specific configurations to the @cucumber/core-ui.module
 */
export class AppSettingsService {
    constructor() {}

    public getConfig(type: string, src: AppSettings) {
        switch (type) {
            case "auth":
                const authSettings = {
                    configOptions: src.keycloakConfigOptions,
                    initOptions: src.keycloakInitOptions,
                };
                return authSettings;
            default:
                throw new Error("Invalid setting type");
        }
    }
}
