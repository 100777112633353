import { NgModule } from "@angular/core";
import { Routes, RouterModule, Router } from "@angular/router";

import { USER_ROLES } from "./configs/permissions.constants";

import { ErrorComponent } from "./components/error/error.component";
import { CanAuthenticationGuard } from "../core/keycloak-auth/auth-guard";

const routes: Routes = [
    {
        path: "",
        pathMatch: "full",
        redirectTo: "reports",
        data: {
            title: "Genesis",
        },
    },
    {
        path: "error",
        component: ErrorComponent,
        data: {
            roles: [USER_ROLES.REPORT_USER],
        },
    },
    {
        path: "variety-manager",
        loadChildren: () =>
            import("./modules/admin/admin.module").then((m) => m.AdminModule),
    },
    {
        path: "task-manager",
        loadChildren: () =>
            import("./modules/task-manager/task-manager.module").then(
                (m) => m.TaskManagerModule,
            ),
    },
    {
        path: "reports",
        loadChildren: () =>
            import("./modules/labour-reports/labour-reports.module").then(
                (m) => m.LabourReportsModule,
            ),
    },
    {
        path: "reports/performance",
        loadChildren: () =>
            import("./modules/labour-reports/labour-reports.module").then(
                (m) => m.LabourReportsModule,
            ),
    },
    {
        path: "hazard-record",
        loadChildren: () =>
            import("./modules/hazard-record/hazard-record.module").then(
                (m) => m.HazardRecordModule,
            ),
    },
    {
        path: "density-charts",
        loadChildren: () =>
            import("./modules/density-charts/density-charts.module").then(
                (m) => m.DensityChartsModule,
            ),
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [
        {
            provide: "CanAuthenticationGuard",
            useValue: (router: Router) => true,
        },
    ],
})
export class AppRoutingModule {}
