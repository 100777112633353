import { Component, OnInit, Input, OnChanges } from "@angular/core";
import { LoadingState } from "../../types/loading-state";

@Component({
    selector: "app-view-master",
    templateUrl: "./view-master.component.html",
    styleUrls: ["./view-master.component.scss"],
})

/**
 * @description a master view container for the application
 * @todo add optional breadcrumbs
 * @todo add optional back button
 */
export class ViewMasterComponent implements OnInit, OnChanges {
    public showContent = false;
    @Input() loadingState: LoadingState;
    @Input() loadingImage: string;

    constructor() {}

    ngOnInit() {}

    ngOnChanges() {
        this.showContent = this.loadingState === LoadingState.Loaded;
    }
}
