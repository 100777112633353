import { Observable } from "rxjs";

/**
 * @descripton Reflects the state of loading operations.
 */
export enum LoadingState {
    Initial,
    Loading,
    Loaded,
    Failed,
}

/**
 * @descripton a interface for defining a LoadingStateService
 */
export interface LoadingStateService {
    readonly loadingState: LoadingState;
    readonly loadingState$: Observable<LoadingState>;
}
