<div class="table-container" *ngIf="!noResults">
    <div *ngIf="factory" class="data-table">
        <div class="table-wrapper" cdkDropListGroup>
            <table
                mat-table
                [dataSource]="dataSource"
                matSort
                [matSortActive]="factory.sortActive"
                matSortDisableClear
                [matSortDirection]="factory.sortDirection"
                (matSortChange)="handleSortBy($event)"
                cdkDropListGroup
            >
                <ng-container
                    *ngFor="
                        let column of displayedColumns;
                        let colIndex = index
                    "
                    matColumnDef="{{ column }}"
                    [sticky]="factory.columns[colIndex]?.fixed"
                    class="table-col"
                >
                    <th mat-header-cell *matHeaderCellDef>
                        <span
                            [matTooltip]="factory.columns[colIndex]?.header"
                            [disabled]="
                                factory.columns[colIndex]?.sortKey === ''
                            "
                            mat-sort-header
                            class="col-header"
                        >
                            {{
                                this.truncateText(
                                    factory.columns[colIndex]?.header
                                )
                            }}
                        </span>
                    </th>

                    <td mat-cell *matCellDef="let item">
                        <ng-container
                            *ngIf="
                                factory.columns[colIndex]?.formatter === 'html'
                            "
                        >
                            <span
                                [innerHTML]="item[column]"
                                class="html-table-content"
                            ></span>
                        </ng-container>

                        <ng-container
                            *ngIf="
                                factory.columns[colIndex]?.formatter === 'text'
                            "
                        >
                            {{ item[column] }}
                        </ng-container>

                        <ng-container
                            *ngIf="
                                actions.length > 0 &&
                                factory.columns[colIndex]?.formatter ===
                                    'actions' &&
                                !disableAction(item)
                            "
                        >
                            <button
                                *ngFor="let action of actions"
                                mat-icon-button
                                [attr.aria-label]="action.label"
                                (click)="onClickAction(item, action.id, $event)"
                            >
                                <mat-icon [color]="action.color">{{
                                    action.icon
                                }}</mat-icon>
                            </button>
                        </ng-container>
                    </td>
                </ng-container>

                <tr
                    mat-header-row
                    *matHeaderRowDef="displayedColumns; sticky: true"
                ></tr>
                <tr
                    mat-row
                    *matRowDef="
                        let row;
                        columns: displayedColumns;
                        let rowIndex = index
                    "
                    (click)="onClickRow(row, $event)"
                ></tr>
            </table>
        </div>

        <!-- pagination -->
        <mat-paginator
            [length]="paginationConfig.totalRecords"
            [pageIndex]="paginationConfig.currentPage"
            [pageSize]="paginationConfig.pageSize"
            [pageSizeOptions]="paginationConfig.pageSizeOptions"
            [showFirstLastButtons]="true"
            (page)="handlePage($event)"
        ></mat-paginator>
    </div>

    <!-- loading spinner -->
    <div
        id="app_table_loading"
        class="app-table-spinner-container"
        *ngIf="loading"
    >
        <mat-spinner diameter="40" class="table-spinner"></mat-spinner>
    </div>
</div>

<!-- no results -->
<div id="app_table_no_results" class="no-results" *ngIf="noResults && !loading">
    No results were found for your query.
</div>
