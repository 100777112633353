/**
 * API Services
 */

import { ApiServiceConfig } from "../types/api-service";

export const defaultService: ApiServiceConfig = {
    action: "GET",
    endpoint: window.env.api_url,
    base_url: window.env.api_url,
    query_state: {
        page: 0,
        size: 10,
    },
};

/**
 * Task Manager
 */
export const getTaskListService: ApiServiceConfig = {
    action: "GET_QUERY",
    endpoint: `${window.env.api_url}Tasks`,
    base_url: `${window.env.api_url}`,
    query_state: {
        page: 0,
        size: 10,
    },
};
export const getTaskService: ApiServiceConfig = {
    action: "GET_QUERY",
    endpoint: `${window.env.api_url}Tasks`,
    base_url: `${window.env.api_url}`,
    query_state: {},
};
export const postTaskService: ApiServiceConfig = {
    action: "CREATE",
    endpoint: `${window.env.api_url}Tasks`,
    base_url: `${window.env.api_url}`,
    query_state: {},
};
export const deleteTaskService: ApiServiceConfig = {
    action: "DELETE",
    endpoint: `${window.env.api_url}Tasks`,
    base_url: `${window.env.api_url}`,
    query_state: {},
};

/**
 * Activity Report
 */
export const getUserActivityListService: ApiServiceConfig = {
    action: "GET_QUERY",
    endpoint: `${window.env.api_url}UserActivities`,
    base_url: `${window.env.api_url}`,
    query_state: {
        page: 0,
        size: 10,
    },
};
export const deleteUserActivityService: ApiServiceConfig = {
    action: "DELETE",
    endpoint: `${window.env.api_url}UserActivities`,
    base_url: `${window.env.api_url}`,
    query_state: {
        page: 0,
        size: 10,
    },
};

/**
 * Performance Report
 */
export const getPerformanceListService: ApiServiceConfig = {
    action: "GET_QUERY",
    endpoint: `${window.env.api_url}Performance/teams`,
    base_url: `${window.env.api_url}`,
    query_state: {
        page: 0,
        size: 10,
    },
};

/**
 * Manage Varieties
 */
export const postVarietyService: ApiServiceConfig = {
    action: "CREATE",
    endpoint: `${window.env.api_url}varieties`,
    base_url: `${window.env.api_url}`,
    query_state: {},
};

export const deleteVarietyService: ApiServiceConfig = {
    action: "DELETE",
    endpoint: `${window.env.api_url}varieties`,
    base_url: `${window.env.api_url}`,
    query_state: {},
};

/**
 * Picklists
 */
export const densityPicklistService: ApiServiceConfig = {
    action: "GET_QUERY",
    endpoint: `${window.env.api_url}ElevationTypes`,
    base_url: `${window.env.api_url}`,
    query_state: {},
};

export const varietyPicklistService: ApiServiceConfig = {
    action: "GET_QUERY",
    endpoint: `${window.env.api_url}varieties`,
    base_url: `${window.env.api_url}`,
    query_state: {},
};

export const timeAllowancePicklistService: ApiServiceConfig = {
    action: "GET_QUERY",
    endpoint: `${window.env.api_url}TimeAllowanceTypes`,
    base_url: `${window.env.api_url}`,
    query_state: {},
};

export const sitesPicklistService: ApiServiceConfig = {
    action: "GET_QUERY",
    endpoint: `${window.env.api_url}Sites`,
    base_url: `${window.env.api_url}`,
    query_state: {},
};

export const housesPicklistService: ApiServiceConfig = {
    action: "GET_QUERY",
    endpoint: `${window.env.api_url}Houses`,
    base_url: `${window.env.api_url}`,
    query_state: {},
};

export const usersPicklistService: ApiServiceConfig = {
    action: "GET_QUERY",
    endpoint: `${window.env.api_url}users`,
    base_url: `${window.env.api_url}`,
    query_state: {},
};
