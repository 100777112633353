<div class="app-site-nav">
    <a
        class="app-site-nav__item"
        *ngFor="let link of links"
        [routerLink]="link.path"
        routerLinkActive="app-site-nav__item--active"
    >
        {{ link.label }}
    </a>
</div>
