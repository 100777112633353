import { Component, OnInit, ChangeDetectorRef } from "@angular/core";

import { User, KeycloakUserProfile } from "../core/types/User";

import { appNavLinks, siteIcon } from "./configs";
import { LoadingStateService } from "./services/app-loading-state.service";
import { AuthService } from "../core/keycloak-auth/public-api";
import { LoadingState } from "./types/loading-state";
import { NavLinks } from "./types/nav-link.model";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
    readonly appVersion: string = window.env.version;

    title = "Turners and Growers Labour Reports";
    loadingState = LoadingState.Loading;
    navLinks: NavLinks = [];
    siteIcon = siteIcon;
    appTitle = "Labour Reports";
    currentTheme = localStorage.getItem("theme_choice")
        ? localStorage.getItem("theme_choice")
        : "light-theme";
    currentUser: User = {
        roles: [],
        profile: {},
    };

    constructor(
        private loadingStateService: LoadingStateService,
        private authService: AuthService,
        private cdr: ChangeDetectorRef,
    ) {
        document.getElementsByTagName("body")[0].className = this.currentTheme;

        this.authService.getUserProfile().then((res: KeycloakUserProfile) => {
            this.currentUser = {
                roles: this.authService.getUserRoles(),
                profile: res,
            };

            appNavLinks.forEach((link) => {
                for (const role of link.permissions) {
                    if (this.currentUser.roles.includes(role)) {
                        this.navLinks.push(link);
                        break;
                    }
                }
            });
        });
    }

    onLogout() {
        this.authService.logout();
    }

    ngOnInit() {
        this.loadingStateService.getLoadingState().subscribe((newState) => {
            this.loadingState = newState;
            this.cdr.detectChanges();
        });
    }

    /**
     * @descripton toggle the theme slection and save the selection to localstorage
     * @param e event data
     */
    onThemeChange(e) {
        this.currentTheme =
            this.currentTheme === "dark-theme" ? "light-theme" : "dark-theme";
        localStorage.setItem("theme_choice", this.currentTheme);
        document.getElementsByTagName("body")[0].className = this.currentTheme;
    }
}
