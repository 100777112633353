import { Component, Input, OnInit } from "@angular/core";
import { LoadingState } from "../../types/loading-state";

@Component({
    selector: "app-ui-blocker",
    templateUrl: "./ui-blocker.component.html",
    styleUrls: ["./ui-blocker.component.scss"],
})

/**
 * @descripton a simple overlay and hides a loading UI with a loading screen animation
 */
export class UiBlockerComponent implements OnInit {
    @Input() state: LoadingState;
    @Input() loadingImage: string;

    constructor() {}

    ngOnInit() {}

    /**
     * @descripton check if the application is loading
     */
    get isLoading(): boolean {
        return (
            this.state === undefined ||
            (this.state && this.state === LoadingState.Loading)
        );
    }
}
