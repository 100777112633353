import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FormlyModule } from "@ngx-formly/core";

import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatInputModule } from "@angular/material/input";
import { FormlyMaterialModule } from "@ngx-formly/material";
import { FormlyMatDatepickerModule } from "@ngx-formly/material/datepicker";
import { FormlyMatNativeSelectModule } from "@ngx-formly/material/native-select";
import { FormlyMatSliderModule } from "@ngx-formly/material/slider";
import { FormlyMatToggleModule } from "@ngx-formly/material/toggle";
import { AutocompleteTypeComponent } from "../components/form-master/custom-components/autocomplete";
import * as validations from "../components/form-master/custom-validations/validation.constants";
import {
    FormlyFieldFileComponent,
    FileValueAccessorDirective,
} from "../components/form-master/custom-components/fileupload";
import { MaterialModule } from "./material.module";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MaterialModule,
        MatInputModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        FormlyMatDatepickerModule,
        FormlyMatToggleModule,
        FormlyMatSliderModule,
        FormlyMaterialModule,
        FormlyModule.forRoot({
            types: [
                {
                    name: "autocomplete",
                    component: AutocompleteTypeComponent,
                    wrappers: ["form-field"],
                },
                {
                    name: "file",
                    component: FormlyFieldFileComponent,
                },
            ],
            validators: [
                {
                    name: "integer",
                    validation: validations.validation_integer,
                },
                {
                    name: "decimal",
                    validation: validations.validation_decimal,
                },
                {
                    name: "phone-number",
                    validation: validations.validation_phoneNumber,
                },
                {
                    name: "email",
                    validation: validations.validation_email,
                },
            ],
            validationMessages: [
                {
                    name: "required",
                    message: "This field is required",
                },
                {
                    name: "max-length",
                    message: validations.validation_message_maxLength,
                },
                {
                    name: "integer",
                    message: validations.validation_message_integer,
                },
                {
                    name: "decimal",
                    message: validations.validation_message_decimal,
                },
                {
                    name: "phone-number",
                    message: validations.validation_message_phoneNumber,
                },
                {
                    name: "email",
                    message: validations.validation_message_email,
                },
                {
                    name: "mat-datepicker-min",
                    message: validations.validation_message_minDate,
                },
                {
                    name: "mat-datepicker-max",
                    message: validations.validation_message_maxDate,
                },
            ],
        }),
        FormlyMatNativeSelectModule,
    ],
    exports: [
        ReactiveFormsModule,
        FormlyModule,
        FormlyMaterialModule,
        FormlyMatNativeSelectModule,
        AutocompleteTypeComponent,
        FormlyFieldFileComponent,
    ],
    declarations: [
        AutocompleteTypeComponent,
        FormlyFieldFileComponent,
        FileValueAccessorDirective,
    ],
})
export class AppFormlyModule {}
