import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";

import { NavLink } from "../../types/nav-link.model";
import { TooltipDefaultsProvider } from "../user-notifications/tooltip-defaults.constants";

@Component({
    selector: "app-site-nav",
    templateUrl: "./site-navigation.component.html",
    styleUrls: ["./site-navigation.component.scss"],
    providers: [TooltipDefaultsProvider],
})

/**
 * @description a ui component that displays the primary navigation for the application
 * @todo finish and test expandable chuild menu support
 */
export class SiteNavComponent {
    @Input() links: NavLink;

    constructor(public router: Router) {}

    goTo(path: string): void {
        this.router.navigate([path]);
    }
}
