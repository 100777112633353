import { Injectable } from "@angular/core";
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
} from "@angular/common/http";
import { Observable, from } from "rxjs";
import { KeycloakService } from "keycloak-angular";
import { switchMap } from "rxjs/operators";

/**
 * @description Auth Interceptor adds the token from keycloak to the request header when calling api endpoints.
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private keycloakService: KeycloakService) {}

    /**
     * @description gets the user permissions token from the keycloak service
     */
    getToken() {
        return from(this.keycloakService.getToken());
    }

    /**
     * @description intercepts every http call to add the token to the request header.
     * @param req Http Request
     * @param next http handler
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
        return this.addAuthorizationHeader(req, next);
    }

    /**
     * @description sets the user permissions token to the header for the api endpoint calls.
     * @param request http request
     * @param next http handler
     */
    addAuthorizationHeader(
        request: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<HttpEvent<any>> {
        return this.getToken().pipe(
            switchMap((token) => {
                request = request.clone({
                    setHeaders: {
                        Authorization: "bearer " + token,
                    },
                });
                return next.handle(request);
            }),
        );
    }
}
