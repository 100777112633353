import { FormControl, ValidationErrors } from "@angular/forms";
import { FormlyFieldConfig } from "@ngx-formly/core";

/**
 * @description form validation Regex
 */
export const validationsRegex = {
    maxFileSize: 104857600,
    integer: /^[-+]?\d+$/,
    decimal: /^(\d+\.?\d{0,9}|\.\d{1,9})$/,
    specialCharacter: /[`~!@#$%^&*()+={}\/\\\[\]|:";'<>?,]/,
    phoneNumber: /^[0-9\()+.\s]{8,20}$/,
    email: /^\w+([\.-]?\w+)+@\w+([\.:]?\w+)+(\.[a-zA-Z0-9]{2,3})+$/,
};

/**
 * @description form validation Methods
 */
export function validation_integer(control: FormControl): ValidationErrors {
    return validationsRegex.integer.test(control.value) || !control.value
        ? null
        : { decimal: true };
}

export function validation_decimal(control: FormControl): ValidationErrors {
    return validationsRegex.decimal.test(control.value) || !control.value
        ? null
        : { decimal: true };
}

export function validation_specialCharacter(
    control: FormControl,
): ValidationErrors {
    return validationsRegex.specialCharacter.test(control.value) ||
        !control.value
        ? { "special-character": true }
        : null;
}

export function validation_phoneNumber(control: FormControl): ValidationErrors {
    return validationsRegex.phoneNumber.test(control.value) || !control.value
        ? null
        : { "contact-number": true };
}

export function validation_email(control: FormControl): ValidationErrors {
    return validationsRegex.email.test(control.value) || !control.value
        ? null
        : { email: true };
}

/**
 * @description form validation Messages
 */
export function validation_message_integer(error, field: FormlyFieldConfig) {
    return error && `"${field.formControl.value}" is not a valid integer.`;
}
export function validation_message_decimal(error, field: FormlyFieldConfig) {
    return error && `"${field.formControl.value}" is not a valid decimal.`;
}
export function validation_message_specialCharacter(
    error,
    field: FormlyFieldConfig,
) {
    return (
        error &&
        `${field.templateOptions.label} is restricted to alphanumeric, hyphen, space, and underscore characters.`
    );
}
export function validation_message_phoneNumber(
    error,
    field: FormlyFieldConfig,
) {
    return error && `"${field.formControl.value}" is not a valid phone number.`;
}
export function validation_message_email(error, field: FormlyFieldConfig) {
    return error && `"${field.formControl.value}" is not a valid email.`;
}
export function validation_message_minDate(error, field: FormlyFieldConfig) {
    return (
        error &&
        `${field.templateOptions.label} must be before the maximum date.`
    );
}
export function validation_message_maxDate(error, field: FormlyFieldConfig) {
    return (
        error &&
        `${field.templateOptions.label} must be after the minium date field.`
    );
}
export function validation_message_maxLength(error, field: FormlyFieldConfig) {
    return (
        error &&
        `${field.templateOptions.label} cannot exceed ${field.templateOptions.maxLength} characters in length.`
    );
}
