import { Injectable } from "@angular/core";
import { KeycloakService } from "keycloak-angular";
import { from as fromPromise, Observable } from "rxjs";
import {
    NgxPermissionsService,
    NgxPermissionsGuard,
    NgxRolesService,
} from "ngx-permissions";
import { Router } from "@angular/router";

@Injectable({ providedIn: "root" })

/**
 * @description a set of methods for handling keycloak api requests
 */
export class AuthService {
    constructor(
        private keycloakService: KeycloakService,
        private permissionsService: NgxPermissionsService,
        private rolesService: NgxRolesService,
    ) {}

    /**
     * @description logout and redirect to the login page
     */
    logout() {
        this.keycloakService.logout();
    }

    /**
     * @description get the token to use when making api requests
     */
    getToken(): Observable<string> {
        return fromPromise(this.keycloakService.getToken());
    }

    /**
     * @description get the roles of the current user
     */
    getUserRoles(): string[] {
        return this.keycloakService.getUserRoles(false);
    }

    getUserProfile() {
        return this.keycloakService.loadUserProfile(true);
    }

    /**
     * @description check if the current user is authenticated against the keycloak instance
     */
    get isAuthenticated(): boolean {
        return this.keycloakService.getKeycloakInstance().authenticated;
    }

    /**
     * @description check if the current user has a specific role
     * @param role the role to check
     */
    isUserInRole(role: string): boolean {
        return this.keycloakService.isUserInRole(role);
    }

    /**
     * @description a public facing method for retriving user roles
     */
    public loadPermissions() {
        this.permissionsService.loadPermissions(this.getUserRoles());
    }

    /**
     * @description generates a NgxPermissionsGuard
     */
    createPermissionsGuard(router: Router): NgxPermissionsGuard {
        return new NgxPermissionsGuard(
            this.permissionsService,
            this.rolesService,
            router,
        );
    }
}
