import { NavLinks } from "../types/nav-link.model";
import { USER_ROLES } from "./permissions.constants";
import { defaultService } from "./service-config.constants";

/**
 * @descripton Configuration of the Site Navigation Items
 */
export const appNavLinks: NavLinks = [
    {
        path: "/reports/activity",
        label: "Activity Report",
        ligature: "",
        id: "nav-link_performance-report",
        permissions: [USER_ROLES.REPORT_USER, USER_ROLES.DEVELOPER],
    },
    {
        path: "/reports/performance",
        label: "Performance",
        ligature: "",
        id: "nav-link_performance-report",
        permissions: [USER_ROLES.REPORT_USER, USER_ROLES.DEVELOPER],
    },
    /* @todo future scope
    {
      path: '/hazard-record',
      label: 'Hazard Record',
      ligature: '',
      id: 'nav-link_hazard-record',
      permissions: [USER_ROLES.REPORT_USER, USER_ROLES.DEVELOPER]
    },
  **/
    {
        path: "/density-charts",
        label: "Density Chart",
        ligature: "",
        id: "nav-link_density-charts",
        permissions: [USER_ROLES.REPORT_USER, USER_ROLES.DEVELOPER],
    },
    {
        path: "/task-manager",
        label: "Task Manager",
        ligature: "",
        id: "nav-link_task-manager",
        permissions: [USER_ROLES.REPORT_USER, USER_ROLES.DEVELOPER],
    },
    {
        path: "/variety-manager",
        label: "Manage Varieties",
        ligature: "settings_applications",
        id: "nav-link_admin",
        permissions: [USER_ROLES.REPORT_USER, USER_ROLES.DEVELOPER],
    },
];
