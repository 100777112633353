<div class="app-theme">
    <mat-toolbar class="site-header">
        <mat-toolbar-row>
            <div>
                <img
                    class="app-icon"
                    *ngIf="siteIcon"
                    [src]="siteIcon"
                    width="80"
                />
            </div>
            <div class="top-nav">
                <app-site-nav [links]="navLinks"></app-site-nav>
            </div>

            <div app-menu-items class="app-menu-items">
                <span
                    class="header-username"
                    *ngIf="currentUser.profile.username"
                    >{{ currentUser.profile.username }}</span
                >
                <div>
                    <button
                        mat-icon-button
                        id="app-account-toggle"
                        class="app-account"
                        [matMenuTriggerFor]="appAccountMenu"
                    >
                        <mat-icon>account_circle</mat-icon>
                    </button>
                    <mat-menu
                        #appAccountMenu="matMenu"
                        class="app-account"
                        overlapTrigger="false"
                        id="app_account_menu"
                    >
                        <button
                            name="app_logout"
                            mat-menu-item
                            (click)="onLogout()"
                        >
                            <mat-icon>logout</mat-icon>
                            <span>Logout</span>
                        </button>
                    </mat-menu>
                </div>
            </div>
        </mat-toolbar-row>
    </mat-toolbar>

    <mat-sidenav-container class="app-root" fullscreen autosize>
        <mat-sidenav-content>
            <app-view-master
                [loadingState]="loadingState"
                [loadingImage]="siteIcon"
            >
                <section
                    class="app-content"
                    fxLayout="column"
                    fxLayout.gt-sm="row"
                >
                    <div id="app_content" fxFlex ngClass.gt-sm="gt-sm">
                        <!-- ROUTE CONTENT -->
                        <div class="app-card">
                            <router-outlet></router-outlet>
                        </div>
                    </div>
                </section>
            </app-view-master>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>
