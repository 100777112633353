import { ValueProvider } from "@angular/core";
import {
    MAT_TOOLTIP_DEFAULT_OPTIONS,
    MatTooltipDefaultOptions,
} from "@angular/material/tooltip";

/**
 * @description default configurations for mat-tooltip
 */
const tooltipDefaults: MatTooltipDefaultOptions = {
    showDelay: 500,
    hideDelay: null,
    touchendHideDelay: null,
};

export const TooltipDefaultsProvider: ValueProvider = {
    provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
    useValue: tooltipDefaults,
};
