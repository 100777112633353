/**
 * import { LABELS } from '../../configs';
 * ...
 * public labels = LABELS;
 */

export const LABELS = {
    /** Titles */
    TITLE_VARIETY_CREATE: "Create a Variety",
    TITLE_VARIETY_EDIT: "Manage Varieties",
    TITLE_DENSITY_CHART: "Density Chart",
    TITLE_TASK_CREATE: "Create Task",
    TITLE_TASK_EDIT: "Edit Task",
    TITLE_ACTIVITY_REPORT: "Activity Report",
    TITLE_PERFORMANCE_REPORT: "Performance Report",
    TITLE_PERFORMANCE_DETAIL: "Performance Details",

    /** Form Labels */
    FORM_VARIETY_SPEED: "Variety Speed",
    FORM_VARIETY_NAME: "Variety name",
    FORM_CRATE_WEIGHT: "Crate Weight",
    FORM_SITE_HOUSE: "Site / House",
    FORM_TASK: "Task",
    FORM_DATE_START: "Date From",
    FORM_DATE_END: "Date To",
    FORM_USER: "User",

    /** Buttons */
    BTN_UPDATE: "update",
    BTN_EDIT: "EDIT",
    BTN_SUBMIT: "save",
    BTN_DISCARD: "cancel",
    BTN_DELETE: "delete",
    BTN_IMPORT: "IMPORT",
    BTN_UPLOAD_ICON: "Upload Icon",
    BTN_REMOVE_ICON: "Remove",
    BTN_CLEAR_FILTER: "clear filters",
    BTN_BACK: "< back",

    /** Table Headers */
    TH_ROW: "ROW",
    TH_GROUND: "GROUND DENSITY",
    TH_DROPPING: "DROPPING",
    TH_ELEVATED: "ELEVATED DENSITY",
    TH_VARIETY: "VARIETY",
    TH_AREA: "AREA",
    TH_USER: "User",
    TH_TASK: "Task",
    TH_LOCATION: "Site / Row",
    TH_LOCATIONS: "Locations",
    TH_SITE_HOUSE: "Site / House",
    TH_DATE: "Date",
    TH_PERFORMANCE: "Performance",
    TH_START_DATE: "Start Date / Time",
    TH_END_DATE: "End Date / Time",
    TH_ACTIONS: "Actions",
    TH_CRATE_COUNT: "Crate Count",

    /** User Notifications */
    ERROR_GENERIC: "An Error has Occurred",
    NOTE_TASK_DELETED: "Task deleted",
    NOTE_TASK_SAVED: "Task saved succesfully",
};
