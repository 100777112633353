import { Injectable } from "@angular/core";
import {
    ActivatedRoute,
    Params,
    Router,
    NavigationStart,
    NavigationEnd,
} from "@angular/router";

interface QueryManager {
    init(params: Params, store: boolean): Promise<Params>;
    add(params: Params, store: boolean): Promise<Params>;
    remove(keys: string[], store: boolean): Promise<Params>;
    getParams(): Promise<Params>;
    setParams(): Promise<Params>;
    setApiParamsUrl(params: Params): string;
}

@Injectable({
    providedIn: "root",
})
export class QueryManagerService implements QueryManager {
    private paramModel: Params = {};

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
    ) {}

    init(activeQuery: Params): Promise<Params> {
        if (Object.keys(this.activatedRoute.snapshot.queryParams).length > 0) {
            activeQuery = this.activatedRoute.snapshot.queryParams;
        }

        this.router.events.forEach((event) => {
            if (event instanceof NavigationStart) {
                this.clearAllParams();
            }

            if (event instanceof NavigationEnd) {
                this.clearAllParams();
            }
        });

        return this.add(activeQuery);
    }

    add(params: Params, store = false): Promise<Params> {
        if (typeof params === undefined) {
            throw new Error("Missing or invalid params");
        }

        return new Promise((resolve, reject) => {
            this.paramModel = Object.assign(this.paramModel, params);
            if (store) {
                this.store();
            }
            resolve(this.paramModel);
        });
    }

    remove(keys: string[], store = false): Promise<Params> {
        if (typeof keys === "undefined") {
            throw new Error("ERROR: missing or invalid keys");
        }

        return new Promise((resolve, reject) => {
            const paramModelClone = Object.assign({ ...this.paramModel });

            keys.forEach((key) => {
                delete paramModelClone[key];
            });
            if (store) {
                this.store();
            }

            this.paramModel = paramModelClone;
            resolve(this.paramModel);
        });
    }

    getParams(): Promise<Params> {
        return new Promise((resolve, reject) => {
            resolve(this.activatedRoute.snapshot.params);
        });
    }

    setParams(): Promise<Params> {
        return new Promise((resolve, reject) => {
            resolve(this.paramModel);
        });
    }

    clearAllParams(): Promise<Params> {
        this.paramModel = {};

        return new Promise((resolve, reject) => {
            resolve(this.paramModel);
        });
    }

    setApiParamsUrl(params) {
        const paramStr = Object.entries(params)
            .map(([key, val]) => {
                return `${key}=${val}`;
            })
            .join("&");

        return paramStr;
    }

    private store(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            resolve(true);
        });
    }
}
