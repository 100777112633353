import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { LoadingState } from "../types/loading-state";

@Injectable({
    providedIn: "root",
})

/**
 * @descripton Holds a simple application state for toggling ui blocking
 */
export class LoadingStateService {
    private _loadingState: LoadingState = LoadingState.Initial;
    private loadingStateSubject = new BehaviorSubject<LoadingState>(
        LoadingState.Initial,
    );

    /**
     * @description get the current LoadingState
     */
    get loadingState() {
        return this._loadingState;
    }

    /**
     * @description update the LoadingState
     */
    set loadingState(state: LoadingState) {
        this._loadingState = state;
    }

    /**
     * @description subscribe the this state via a subject if you're into that kind of thing
     */
    public getLoadingState(): BehaviorSubject<LoadingState> {
        return this.loadingStateSubject;
    }

    public setLoadingState(state: LoadingState) {
        if (state === LoadingState.Loaded) {
            // lets the loading animation finish a cycle
            setTimeout(() => {
                this.loadingState = state;
                this.loadingStateSubject.next(state);
            }, 1000);
        } else {
            this.loadingState = state;
            this.loadingStateSubject.next(state);
        }
    }
}
