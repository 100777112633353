import { HttpClientModule } from "@angular/common/http";
import { NgModule, ModuleWithProviders, APP_INITIALIZER } from "@angular/core";
import { AuthHttpInterceptorProvider } from "./auth-provider";
import { KeycloakAngularModule, KeycloakService } from "keycloak-angular";
import { initializer } from "./keycloak-provider";

@NgModule({
    imports: [HttpClientModule, KeycloakAngularModule],
})
export class KeycloakAuthModule {
    static forRoot(config: any): ModuleWithProviders<KeycloakAuthModule> {
        return {
            ngModule: KeycloakAuthModule,
            providers: [
                AuthHttpInterceptorProvider,

                {
                    provide: APP_INITIALIZER,
                    useFactory: initializer,
                    multi: true,
                    deps: [KeycloakService],
                },
                {
                    provide: "config",
                    useValue: config,
                },
            ],
        };
    }
}
