import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";

import { AppRoutingModule } from "./app-routing.module";
import { SharedModule } from "src/core/shared.module";
import { AppSettingsService } from "./services/app-settings.service";
import { KeycloakAuthModule } from "src/core/keycloak-auth/keycloak.auth.module";
import { NgxPermissionsModule } from "ngx-permissions";
import { AppComponent } from "./app.component";
import { ErrorComponent } from "./components/error/error.component";
import { LoadingStateService } from "./services/app-loading-state.service";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { QueryManagerService } from "./services/query-manager.service";

const settingsService = new AppSettingsService();

export function authConfig() {
    return settingsService.getConfig("auth", window.env);
}

@NgModule({
    declarations: [AppComponent, ErrorComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        SharedModule,
        KeycloakAuthModule.forRoot({ config: authConfig }),
        NgxPermissionsModule.forRoot(),
        HttpClientModule,
    ],
    providers: [
        LoadingStateService,
        QueryManagerService,
        { provide: MAT_DATE_LOCALE, useValue: "en-GB" },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
